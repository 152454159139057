/* eslint-disable no-unused-vars */
// import axios from "axios";
import authHeader from "@/services/api/auth-header";
import {
  data
} from "autoprefixer";
import callEndpoint from "@/services/api/index";
import METHODS from "@/services/api/apiMethods";
import axios from "axios";
import {email} from "@vee-validate/rules";

let RESOURCE_URL = "";
if (process.env.NODE_ENV === "development") {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
} else {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
}
const {
  POST,
  //     PUT,
  PATCH,
  DELETE,
} = METHODS;

export const uploadToCloudinary = async (data) => {

  return axios.post(process.env.VUE_APP_CLOUDINARY_URL, data);
};
const getKudaJwtToken = () => {
  return sessionStorage.getItem('kudaJwt')
}

const header = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + getKudaJwtToken(),
}
// https://res.cloudinary.com/demo/image/upload/f_auto,q_auto,e_trim,b_white/bo_1px_solid_gray/docs/rmv_bgd/stuffed.jpg
// Get countries and their states
const COUNTRY_BASE_URL = "https://countriesnow.space/api/v0.1/countries/states";
const CITY_BASE_URL = "https://countriesnow.space/api/v0.1/countries/state/cities";

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};
const paystackAxios = axios.create({
  baseURL: 'https://api.paystack.co',
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_PAYSTACK_LIVE_SECRET_KEY}`,
    'Content-type': 'application/json',
  }
});
export const getKudaAuthToken = async (data) => {
  const url = 'https://kuda-openapi.kuda.com/v2/Account/GetToken'
  return axios.post(url, data, {})
}

export const getAllBanks = async (data, header) => {
  const url = 'https://kuda-openapi.kuda.com/v2/'
  return axios.post(url, data, {
    headers: header
  })
}
export function getPaymentReferences() {
  return paystackAxios.get('/transaction');
}

export const getAllCountries = async () => {
  return axios.get(COUNTRY_BASE_URL, {
    headers
  });
};
export const getAllStatesByCountry = async (country) => {
  return axios.post(COUNTRY_BASE_URL, country, {
    headers
  });
};

export const getAllCitiesInAState = async (data) => {
  return axios.post(CITY_BASE_URL, data, {
    headers
  });
};

export const getLatLong = async (data) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${data.houseNumber}+${data.streetName}+${data.city},+${data.state},+${data.country}
  &key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}`;
  return axios.get(url);
};


export const onBoardTransportCompany = async (data) => {
  const url = `/api/transport-company/on-boarding`;
  return callEndpoint({
    method: POST,
    url,
    data,
    needsAuth: true
  });
};

export const getInsuraceProviderData = async (data) => {
  const url = `/api/insurance-provider/get-by-email`;
  return callEndpoint({
    method: POST,
    url,
    needsAuth: true,
    data
  });
};
export const getPaystackAccountDetails = async (email) =>{
  const url = `/api/paystack/get-account-by-user-email/${email}`
  return callEndpoint({url})
}

export const getWalletBalanceByUserEmail = async (email) =>{
  const url = `/api/wallet/get-wallet-balance/${email}`
  return callEndpoint({url})
}

export const getWalletByUserEmail = async (email) =>{
  const url = `/api/wallet/get-wallet-by-user-email/${email}`
  return callEndpoint({url})
}

export const getAllCustomersByProvider = async (data) => {
  const url = `/api/insurance-transaction/get-all-customers-by-provider`;
  return callEndpoint({ method: POST, url,data });
};
export  const getAllClaimsByProvider = async (data)=>{
  const url = `/api/insurance/claim/get-all-by-provider`
  return callEndpoint({method:POST,url,data})
}

export  const getDashboardStat = async (data)=>{
  const  url = `/api/insurance-transaction/get-dashboard-stat-by-provider`
  return callEndpoint({method:POST,url,data})
}

export const getAllPolicy = async (data) => {
  const url = `/api/insurance-transaction/get-all-by-provider`;
  return callEndpoint({method:POST,url,data});
}

const paystackHeaders = {
  'Content-Type': 'application/json',
  "Authorization":  'Bearer '+process.env.VUE_APP_PAYSTACK_LIVE_SECRET_KEY
};

export const  makePayment = async  (data) =>{
  const url = `https://api.paystack.co/transaction/initialize`
  return axios.post(url,data,{headers:paystackHeaders})
}

export const verifyPaystackPayment = async  (userId, reference,data) =>{
  const  url = `/api/paystack/verify-payment/${userId}/${reference}`
  return callEndpoint({method:PATCH,url,data})
}
export  const withdrawFromWallet = async (data) =>{
  const url = `/api/wallet/transfer-funds-from-wallet`
  return callEndpoint({method:PATCH,url,data})
}


