import {
  createStore
} from 'vuex'
import snackBar from "./snackBar";
import menu from "./menu"
import companyProfile from './companyProfile';
import walletStore from "@/store/walletStore";
import insuranceProvider from "@/store/insuranceProvider";

export default createStore({
  modules: {
    snackBar,
    menu,
    companyProfile,
    walletStore,
    insuranceProvider,
  }
})