import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const state = {
    depositAmount: 100,
    transaction:{}
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};