import {
  createRouter,
  createWebHistory
} from 'vue-router'
// import LoginView from "@/views/LoginView";
import {
  getUserData,
  isTokenValid,
} from '@/services/api/AuthApiService';

const routes = [{
    path: "/",
    name: "InsuranceLogin",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/dashboard",
    name: "Insurance",
    meta: {
      loginRequired: true
    },
    component: () => import("@/views/DashboardSculptor.vue"),
    redirect: {
      name: "Dashboard"
    },
    children: [{
        path: "",
        name: "Dashboard",
        component: () => import("@/views/dashboard/LandingDashboard.vue")
      },
      {
        path: "/customers",
        name: "CustomersView",
        component: () => import("@/views/customers/CustomersView.vue")

      },
      {
        path: "/wallet",
        name: "WalletDashboard",
        component: () => import("@/views/wallet/WalletDashboard.vue")
      },
      {
        path: "/settings",
        name: "AccountSetting",
        component: () => import("@/views/AccountSetting.vue")
      },
      {
        path: "/claims",
        name: "ClaimsView",
        component: () => import("@/views/claims/ClaimsView.vue")
      },

      {
        path: "/policy",
        name: "PolicyDashboard",
        component: () => import("@/views/policy/PolicyDashboard.vue")
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: "smooth"
    };
  },
})
router.beforeEach((to, from, next) => {

  let token = to.query.access_token
  if (token){
    getUserData(token)
    router.replace({query:null}).then()
    token = null
    router.push({name: 'Dashboard'}).then(() => {})

  }

  let userData = JSON.parse(sessionStorage.getItem('userData'))
  if (to.meta.loginRequired) {
    if (!userData) {
      next({
        name: 'InsuranceLogin'
      })
    } else {
      isTokenValid(userData.exp)
    }
  }
  next();
})

export default router