import mutations from "@/store/insuranceProvider/mutations";
import actions from "@/store/insuranceProvider/actions";
import getters from "@/store/insuranceProvider/getters";

const state = {
    loginInsuranceProvider:{},
}

export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}