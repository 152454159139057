import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueFeather from 'vue-feather'
import paystack from "vue3-paystack";
import vueDayjs from "vue3-dayjs-plugin";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import '@/assets/css/index.css'
import '@/assets/css/styles.css'
import {
  loadFonts
} from './plugins/webfontloader'

import {
  defineRule
} from 'vee-validate';
import {
  required,
  email,
  url
} from '@vee-validate/rules';
defineRule('required', required);
defineRule('email', email);
defineRule('url', url);
loadFonts()
dayjs.extend(LocalizedFormat);
dayjs().format("L LT");

const app = createApp(App)
// app.provide('$dayjs', dayjs);
app.use(router).use(store).use(vuetify);
app.use(vueDayjs)
app.component(VueFeather.name, VueFeather);
app.component('payStack', paystack)
app.mount('#app');