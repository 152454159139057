import {getInsuraceProviderData} from "@/services/api/APIService";
export default {
    async setInsuranceProviderData({commit}) {
        let loginInsuranceProvider = JSON.parse(sessionStorage.getItem("loginInsuranceProviderData"))
        if (!loginInsuranceProvider) {
            let userData = JSON.parse(sessionStorage.getItem("userData"))
            if (userData) {
                return await getInsuraceProviderData(userData).then(res => {
                    commit("SET_LOGIN_INSURANCE_PROVIDER_DATA", res.data);
                })
            }
        } else {
            commit("SET_LOGIN_INSURANCE_PROVIDER_DATA", loginInsuranceProvider);
        }
    },
}