export default function authHeader() {
    let user = JSON.parse(sessionStorage.getItem('userToken'));

    if (typeof user === 'object' && user !== undefined && user !== null) {
        if (Object.keys(user).length && user.access_token){
            return { Authorization: `Bearer ${user.access_token}` };
        }
    }
    else if (user){
        return {Authorization: `Bearer ${user}`}
    }
    else {
        return {};
    }


}
